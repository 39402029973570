














































import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';
import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { RootGetter } from '@/features/core/store';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import { AppHomeAppViewQuery, AppHomeAppViewQueryVariables } from './__generated__/AppHomeAppViewQuery';

@Component({
  apollo: {
    view: {
      query,
      poolInterval: 60000,
      variables(this: AppView): AppHomeAppViewQueryVariables {
        return {
          aggregationRoles: Object.values(this.deviceRoleMap)
            .filter(({ aggregationWidget }) => aggregationWidget?.enabled === true)
            .map(({ name }) => name),
          userId: this.userId,
        };
      },
      manual: true,
      result(this: AppView, { data }: ApolloQueryResult<AppHomeAppViewQuery>): void {
        this.alerts = data.alerts;
        this.spots = data.spots;
        this.spotRoleAggregations = data.spotRoleAggregations;
        this.gateways = data.gateways;
        this.properties = data.properties;
      },
    },
  },
  data() {
    return { alerts: undefined, spots: undefined, gateways: undefined, properties: undefined };
  },
})
export default class AppView extends Mixins(DeviceRoleMapMixin, TreeNodeLocationGeneratorMixin, AppRedirectionMixin) {
  private alerts?: AppHomeAppViewQuery['alerts'];
  private spots?: AppHomeAppViewQuery['spots'];
  private spotRoleAggregations?: AppHomeAppViewQuery['spotRoleAggregations'];
  private gateways?: AppHomeAppViewQuery['gateways'];
  private properties?: AppHomeAppViewQuery['properties'];
  private isProd = process.env.VUE_APP_NODE_ENV === 'production' || location.host === 'gk4null.de';

  @RootGetter
  private readonly userId?: string;
}
